<template>
  <div class="bet-rebate">
    <HomeHeader />
    <div class="img">
      <img src="@/assets/images/pc/Inside/Inside-pc-3.png" alt="" />
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/pc/HomeHeader";

import elementHeight from "@/mixins/pc/elementHeight";

export default {
  name: "BetRebate",
  mixins: [elementHeight],
  components: {
    HomeHeader,
  },
};
</script>

<style lang="less" scoped>
.bet-rebate {
  font-size: 16px;
  img {
    width: 100%;
  }
}
</style>
